* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: "Inter";
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

/** 
 Define some common layout features here
 */
.main-layout {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 2em auto;
  padding: 0 2em;
  max-width: 1280px;
}

.page-content {
  display: flex;
  margin-top: 2em;
  width: 100%;
}

aside {
  width: 200px;
  margin-right: 2em;
}

main {
  flex: 1;
}

main > div {
  display: flex;
  flex-direction: column;
}

main div:has(> .container) {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border-radius: 1em;
  background-color: whitesmoke;
  font-size: 2em;
  margin-bottom: 1em;
}

main > div .container {
  display: flex;
  padding: 0.5em;
  border-bottom: 1px solid black;
}

/*
 Define some basic elements here.
*/
h2 {
  margin-bottom: 1em;
}

h5 {
  margin-bottom: 0.5em;
}

input[type="text"],
input[type="number"] {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid black;
  border-radius: 0.5em;
}

::-webkit-inner-spin-button {
  display: none;
}

select {
  margin: 1em 0;
  padding: 1em;
  border-radius: 0.5em;
}

button[type="button"],
button[type="submit"] {
  margin: 1em 0;
  padding: 1em;
  border-radius: 0.5em;
  border: 1px solid white;
  background-color: black;
  color: white;
  cursor: pointer;
}

button[type="button"]:hover,
button[type="submit"]:hover {
  background-color: gray;
  color: white;
}

button[type="button"]:disabled,
button[type="submit"]:disabled {
  background-color: lightgray;
  color: gray;
  cursor: default;
}

button.danger {
  background-color: red;
}

main a {
  font-size: 0.5em;
  align-self: flex-end;
  color: darkslateblue;
}

main a:hover {
  text-decoration: underline;
}

select {
  margin: 0;
}
